<template>
  <div>
    <div class="is-flex is-justify-content-space-between">
      <p class="is-size-4 is-size-3-desktop has-text-weight-bold">
        {{ $t("af:stores.title") }}
      </p>
      <div class="is-flex pp-search-input-wrapper">
        <b-input
          :placeholder="$t('pp:list.search.placeholder')"
          type="search"
          icon="search"
          icon-clickable
          class="pp-search-input"
          @keydown.native.enter.prevent="search"
          v-model="searchInput"
        />
      </div>
    </div>
    <div class="is-flex mt-6">
      <store-category-button
        v-for="({ name, icon, id }, index) in categories"
        :key="`store-category-button-${index}`"
        :label="name"
        :icon="icon"
        class="mr-3"
        :selected="!isCategoryDeselected(id)"
        @click.native="filterByCategory(id)"
      />
    </div>
    <div
      v-for="(merchants, key) in filteredMerchantsByRole"
      :key="`merchant-category-${key}`"
      class="mt-6"
    >
      <div
        class="is-flex is-align-items-center is-justify-content-space-between"
      >
        <span class="is-size-4 has-text-weight-bold">{{
          findCategoryById(key).name
        }}</span>
        <b-button
          v-if="merchantsByRole[key].length > 3"
          type="is-text"
          @click="showMore(key)"
          >{{ $t("af:stores.show_all") }}
        </b-button>
      </div>
      <div
        class="columns is-variable mt-3 is-8"
        :class="{ 'is-multiline': getShowMore(key) }"
      >
        <store-small-card
          v-for="merchant in merchants"
          :key="`merchant-card-${merchant.id}`"
          :merchant-name="merchant.name"
          :label="merchant.website"
          :icon="merchant.logo"
          :image="merchant.backgroundImage"
          class="column is-full-tablet is-4 cursor-pointer"
          @click.native="goToStore(merchant.id)"
        />
      </div>
    </div>
  </div>
</template>

<script>
import i18n from "@/plugins/i18n";
import { mapActions, mapGetters } from "vuex";
import _find from "lodash.find";
import _indexOf from "lodash.indexof";
import _forEach from "lodash.foreach";

export default {
  name: "Stores",
  components: {
    StoreCategoryButton: () =>
      import("@/components/stores/StoreCategoryButton.vue"),
    StoreSmallCard: () => import("@/components/stores/StoreSmallCard.vue"),
  },
  data() {
    return {
      searchInput: "",
      showMoreGroup: [],
      categoryDeselelectList: [],
      searchFields: ["name"],
    };
  },
  computed: {
    ...mapGetters({
      categories: "merchant/getFilledCategories",
      merchants: "merchant/getMerchants",
      merchantsByRole: "merchant/getMerchantsByCategory",
    }),
    filteredMerchantsByRole() {
      const _filteredMerchantsByRole = { ...this.merchantsByRole };
      _forEach(this.categoryDeselelectList, (key) => {
        delete _filteredMerchantsByRole[key];
      });
      return _filteredMerchantsByRole;
    },
  },
  methods: {
    ...mapActions({
      fetchCategories: "merchant/fetchCategories",
      fetchMerchants: "merchant/fetchMerchants",
    }),

    findCategoryById(id) {
      return _find(this.categories, (category) => category.id === Number(id));
    },

    arrayToggler(element, array) {
      const groupIndex = _indexOf(array, element);
      if (groupIndex >= 0) {
        array.splice(groupIndex, 1);
      } else {
        array.push(element);
      }
      return array;
    },

    showMore(categoryId) {
      this.showMoreGroup = this.arrayToggler(
        Number(categoryId),
        this.showMoreGroup
      );
    },

    getShowMore(categoryId) {
      return _indexOf(this.showMoreGroup, Number(categoryId)) >= 0;
    },

    filterByCategory(categoryId) {
      this.categoryDeselelectList = this.arrayToggler(
        Number(categoryId),
        this.categoryDeselelectList
      );
    },

    isCategoryDeselected(categoryId) {
      return _indexOf(this.categoryDeselelectList, Number(categoryId)) >= 0;
    },

    goToStore(storeId) {
      this.$router.push({ name: "Store", params: { storeId } });
    },

    async search() {
      let searchTerm = this.searchInput.trim().toLowerCase();

      try {
        await this.fetchMerchants({
          fields: this.searchFields,
          text: searchTerm,
        });
      } catch (e) {
        this.$buefy.toast.open({
          message: i18n.t("pp:list.search.message.error"),
          type: "is-danger",
        });
      }
    },
  },
  mounted() {
    this.fetchCategories();
    this.fetchMerchants();
  },
};
</script>
